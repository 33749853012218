import logo from "../assets/arms-logo(1)1.png";
import elipse1 from "../assets/Ellipse661.png";
import elipse2 from "../assets/Group3581.png";
import elipse3 from "../assets/Ellipse63.png";
import elipse4 from "../assets/Ellipse64.png";
import group1 from "../assets/Group3512.png";
import group2 from "../assets/Group3516.png";
import group3 from "../assets/Group3517.png";
import group4 from "../assets/Groupa.png";
import group5 from "../assets/Groupb.png";
import group6 from "../assets/Groupx.png";
import group7 from "../assets/Groupy.png";
import group8 from "../assets/Groupz.png";
import group9 from "../assets/twitter(1)1.png";
import group10 from "../assets/linkedin(1)1.png";
import vector1 from "../assets/Vector68.png";
import vector2 from "../assets/Vector69.png";
import rect1 from "../assets/Rectangle130.webp";
import rect2 from "../assets/Rectangle27.webp";
import rect3 from "../assets/Rectangle28.webp";
import rect4 from "../assets/Rectangle29.webp";
import rect5 from "../assets/Rectangle30.webp";
import twiter from "../assets/twitter(1)1.svg";
import linkedn from "../assets/Path_2520.svg";
import dabas from "../assets/RC_Dabas-removebg-preview.webp";

import mondal from "../assets/som.webp";
import thapur from "../assets/sapan.webp";
import chart from "../assets/chart-simple-horizontal1.png";
import satellite from "../assets/satellite-11.svg";
import evelop from "../assets/envelop-icon1.svg";
import human from "../assets/human-gear-icon1.svg";
import admed from "../assets/Shakeel_Ahmed-removebg-preview.webp";
import pk from "../assets/pk.png";
import eclips from "../assets/Ellipse53.png";
import img1 from "../assets/Rectangle11.webp";
import img2 from "../assets/Rectangle10.webp";
import img3 from "../assets/Rectangle10(1).webp";
import img4 from "../assets/Rectangle10(2).webp";
import img5 from "../assets/Rectangle10(3).webp";
import img6 from "../assets/Rectangle11(1).webp";
import img7 from "../assets/Rectangle12.webp";
import img8 from "../assets/Rectangle13.webp";
import img9 from "../assets/Rectangle14.webp";
import img10 from "../assets/Homepage(11).webp";
import img11 from "../assets/Homepage(10).webp";
import img12 from "../assets/enviro.webp";
import img13 from "../assets/maninder.webp";
import agri1 from "../assets/FarmWiseCropyieldEstimationandPrediction.webp";
import agri2 from "../assets/LocationWiseSoilMoistureAssessment.webp";
import agri3 from "../assets/PredictingOptimumTimeofCropHarvest.webp";
import agri4 from "../assets/Rectangle86.png";
import agri5 from "../assets/agri5.webp";
import infra1 from "../assets/infra1.webp";
import infra2 from "../assets/infra2.webp";
import infra3 from "../assets/infra3.webp";
import infra4 from "../assets/infra4.webp";
import env1 from "../assets/env1.webp";
import env2 from "../assets/AutomaticLandUseLandCoverClassification.webp";
import env3 from "../assets/env3.webp";
import env4 from "../assets/env4.webp";
import env5 from "../assets/env5.webp";
import env6 from "../assets/env6.webp";
import time1 from "../assets/time1.webp";
import time2 from "../assets/time2.webp";
import time3 from "../assets/time3.webp";
import time4 from "../assets/time4.webp";

const images = {
  logo,
  elipse1,
  elipse2,
  elipse3,
  elipse4,
  group1,
  group2,
  group3,
  group4,
  group5,
  group6,
  group7,
  group8,
  group9,
  group10,
  vector1,
  vector2,
  rect1,
  rect2,
  rect3,
  rect4,
  rect5,

  twiter,
  linkedn,
  dabas,

  mondal,
  thapur,
  chart,
  satellite,
  evelop,
  human,
  admed,
  pk,
  eclips,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  agri1,
  agri2,
  agri3,
  agri4,
  agri5,
  infra1,
  infra2,
  infra3,
  infra4,
  env1,
  env2,
  env3,
  env4,
  env5,
  env6,
  time1,
  time2,
  time3,
  time4,
};

export default images;
